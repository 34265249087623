import React from "react";
import HomeInitial from "../components/home/HomeInitial";
import Sidebar from "../components/home/Sidebar";
import Logo from "../../img/logos/Wordmark_Dark.svg";
import AOS from "aos";
import '../../style/main.scss'
// import { Calendar } from "@fullcalendar/core";

class Home extends React.Component {

    componentDidMount() {
        AOS.init({
            duration : 2000 
        });
    }

    render() {
        return (

            <div>
                <div className="animation">
                <svg 
                 width="100%" viewBox="0 0 1440 880" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g> 
                    <circle opacity="0.5" cx="1264.11" cy="747.115" r="30" transform="rotate(-0.220059 1264.11 747.115)" fill="#FD643D"/>
                    <circle opacity="0.5" cx="962.794" cy="431.575" r="30" transform="rotate(-165.449 962.794 431.575)" fill="#FEC44A"/>
                    <circle opacity="0.5" cx="496.115" cy="68.115" r="30" transform="rotate(-0.220059 496.115 68.115)" fill="#133B51"/>
                    <circle opacity="0.5" cx="1321.3" cy="79.8139" r="30" transform="rotate(-0.220059 1321.3 79.8139)" fill="#92F9F3"/>
                    <circle opacity="0.5" cx="1344.44" cy="636.729" r="30" transform="rotate(-0.220059 1344.44 636.729)" fill="#92F9F3"/>
                    <path className="path" d="M1381.5 368.784L1100.54 368.784C1077.53 368.784 1063.09 393.615 1074.46 413.614L1110.71 477.349" stroke="#133B51" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M1294.34 514.182L1192.96 657.7" stroke="#FEC44A" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M820.97 -5.26511L878.3 69.5016C884.002 76.938 892.851 81.2828 902.222 81.2468L1314.04 79.6651" stroke="#92F9F3" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M994.17 192.108L1274.66 191.03C1284.84 190.991 1294.34 196.116 1299.9 204.642L1348.36 278.948" stroke="#133B51" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M1372.57 149.618L1535.5 391.994" stroke="#FEC44A" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="1110.85" cy="484.423" r="21.1258" transform="rotate(-0.220059 1110.85 484.423)" fill="#133B51"/>
                    <circle cx="1192.15" cy="658.251" r="21.1258" transform="rotate(-0.220059 1192.15 658.251)" fill="#FEC44A"/>
                    <circle cx="649.207" cy="126.207" r="21.1258" transform="rotate(-0.220059 649.207 126.207)" fill="#FD643D"/>
                    <circle cx="741.207" cy="186.207" r="21.1258" transform="rotate(-0.220059 741.207 186.207)" fill="#FEC44A"/>
                    <circle cx="214.207" cy="180.207" r="21.1258" transform="rotate(-0.220059 214.207 180.207)" fill="#FEC44A"/>
                    <path className="path" d="M1341.82 636.531L1443.36 487.024C1452.77 473.179 1448.94 454.307 1434.89 445.218V445.218C1430.03 442.077 1424.36 440.416 1418.57 440.438L1262.68 441.037" stroke="#92F9F3" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="1345.12" cy="635.995" r="21.1258" transform="rotate(119.243 1345.12 635.995)" fill="#92F9F3"/>
                    <circle cx="1321.6" cy="79.9386" r="21.1258" transform="rotate(-0.220059 1321.6 79.9386)" fill="#92F9F3"/>
                    <circle cx="1348.21" cy="284.207" r="21.1258" transform="rotate(-0.220059 1348.21 284.207)" fill="#133B51"/>
                    <path className="path" d="M1115.72 554.926L1116.35 719.81C1116.42 736.378 1129.9 749.758 1146.47 749.694L1261.9 749.251" stroke="#FD643D" strokeWidth="15" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="1263.84" cy="746.751" r="21.1258" transform="rotate(-0.220059 1263.84 746.751)" fill="#FD643D"/>
                    <circle r="21.1258" transform="matrix(-0.999993 -0.00384075 -0.00384075 0.999993 482.793 684.707)" fill="#92F9F3"/>
                    <path className="path" d="M1355.88 348.996L1126.06 349.879C1102.58 349.969 1087.89 375.329 1099.5 395.744L1135.27 458.646" stroke="#92F9F3" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M1293.05 465.925L1191.67 609.443" stroke="#92F9F3" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M933.49 147L851.66 263.133C845.91 271.293 836.552 276.147 826.57 276.147L659.111 276.147C651.031 276.147 643.277 272.962 637.532 267.281L571.5 202" stroke="#92F9F3" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M771 -22.1075L828.12 52.3866C833.954 59.9948 843.008 64.4399 852.595 64.4031L1264.07 62.8227" stroke="#FD643D" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M1008.64 168.941L1237.49 169.411C1247.65 169.432 1257.14 174.48 1262.84 182.893L1312.63 256.413" stroke="#92F9F3" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M1336.5 138.756L1501.44 383.124" stroke="#FD643D" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M1305.75 618.492L1407.29 468.986C1416.7 455.14 1412.87 436.268 1398.82 427.18V427.18C1393.96 424.038 1388.29 422.377 1382.51 422.4L1226.61 422.998" stroke="#133B51" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M1084.03 524.833L1084.66 689.024C1084.72 705.975 1098.52 719.664 1115.47 719.599L1230.21 719.158" stroke="#92F9F3" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path className="path" d="M671 -20.543L775.132 105.785C784.13 116.7 784.279 132.418 775.491 143.504L745.5 181.334" stroke="#FEC44A" strokeWidth="15"/>
                    <path className="path" d="M714 -11.543L817.935 114.546C827.022 125.569 827.074 141.472 818.061 152.555L768.5 213.5" stroke="#133B51" strokeWidth="5"/>
                    <path className="path" d="M213.885 181.137L401.851 181.083L571.707 181.083C579.584 181.083 587.146 184.181 592.758 189.709L647.242 243.371C652.854 248.899 660.416 251.997 668.293 251.997L870.562 251.997C880.25 251.997 889.342 247.319 894.973 239.435L971 133" stroke="#FEC44A" strokeWidth="15"/>
                    <path className="path" d="M644.5 125H199.349C189.711 125 180.659 120.369 175.02 112.552L83 -15" stroke="#FD643D" strokeWidth="15"/>
                    <path className="path" d="M608.5 105H157.349C147.711 105 138.659 100.369 133.02 92.552L41 -35" stroke="#133B51" strokeWidth="5"/>
                    <path className="path" d="M137 183.5V654.5C137 671.069 150.431 684.5 167 684.5H483" stroke="#92F9F3" strokeWidth="15"/>
                    <path className="path" d="M169 266.5V689C169 705.569 182.431 719 199 719H440" stroke="#FD643D" strokeWidth="5"/>
                    <path className="path" d="M498 68H603.978C629.738 68 643.519 37.6723 626.576 18.2683L580.5 -34.5" stroke="#133B51" strokeWidth="15"/>
                    <path className="path" d="M867 814.936L913.974 636.788C915.748 630.06 919.806 624.157 925.452 620.091L1003.44 563.935C1015.29 555.403 1019.28 539.578 1012.88 526.451L967.584 433.474" stroke="#FEC44A" strokeWidth="15"/>
                    <path className="path" d="M911 738L950.542 580.989C952.423 573.522 957.103 567.064 963.613 562.952L1033.55 518.775C1047.08 510.232 1051.52 492.58 1043.65 478.653L998.47 398.7" stroke="#133B51" strokeWidth="5"/>
                    <circle cx="962.874" cy="431.803" r="21.1258" transform="rotate(-165.449 962.874 431.803)" fill="#FEC44A"/>
                    <circle cx="495.98" cy="67.9154" r="21.1258" transform="rotate(-0.220059 495.98 67.9154)" fill="#133B51"/>
                    <path className="path" d="M536 53H561.145C586.959 53 600.72 22.5629 583.668 3.18292L569.429 -13" stroke="#FD643D" strokeWidth="5"/>
                    <circle cx="74.6797" cy="124.876" r="21.1258" transform="rotate(-44.6463 74.6797 124.876)" fill="#FEC44A"/>
                    <path className="path" d="M73.7994 116.201L76.8126 614.83" stroke="#FEC44A" strokeWidth="15"/>
                    <path className="path" d="M49.03 163.856L47.4414 650.345" stroke="#133B51" strokeWidth="5"/>
                    </g>
                    <g className="st25">
                        <path className="st9" d="M210.3,312c0-11.4,7.3-20.6,19.7-20.6c7.8,0,13.6,3.8,16.8,9.5l-5.2,3.2c-2.3-4.4-6.5-7.3-11.6-7.3
                            c-8.3,0-13.4,6.5-13.4,15.2c0,8.7,5.2,15.2,13.4,15.2c5.1,0,9.4-3,11.6-7.4l5.2,3.2c-3.2,5.7-9,9.5-16.8,9.5
                            C217.6,332.6,210.3,323.4,210.3,312z"/>
                        <path className="st9" d="M249.8,317.1c0-8.5,6.9-15.5,15.5-15.5c8.5,0,15.5,7,15.5,15.5c0,8.5-7,15.5-15.5,15.5
                            C256.7,332.6,249.8,325.6,249.8,317.1z M275.2,317.1c0-5.8-4.3-10.1-9.8-10.1c-5.6,0-9.8,4.4-9.8,10.1s4.2,10.1,9.8,10.1
                            C271,327.2,275.2,322.9,275.2,317.1z"/>
                        <path className="st9" d="M316.3,302.2v27.5c-0.1,9-7.7,15.2-15.5,15.2c-9.2,0-13.9-6.4-14.9-9.3l4.6-2.3c1,2.6,5,6.4,10.4,6.4
                            c5.9,0,9.7-5.3,9.8-10h-0.1v-0.8c-2.7,2.3-6.2,3.8-10.1,3.8c-8.6,0-15.5-6.9-15.5-15.5c0-8.5,6.9-15.5,15.5-15.5
                            c3.9,0,7.4,1.4,10.1,3.8v-3.3H316.3z M310.4,317.2c0-5.7-4.3-10.1-9.8-10.1c-5.6,0-9.8,4.4-9.8,10.1c0,5.8,4.2,10.1,9.8,10.1
                            C306.1,327.3,310.4,323,310.4,317.2z"/>
                        <path className="st9" d="M350,314.8v17.3h-5.8V315c0-4.6-3.3-8.1-7.7-8.1s-7.7,3.5-7.7,8.2v17h-5.7v-30h5.7v3.7
                            c2.2-2.6,5.5-4.2,8.9-4.2C344.8,301.6,350,307.5,350,314.8z"/>
                        <path className="st9" d="M357.3,292c0-1.8,1.4-3.2,3.2-3.2c1.7,0,3.2,1.4,3.2,3.2s-1.5,3.2-3.2,3.2C358.8,295.2,357.3,293.8,357.3,292z
                            M357.7,302.1h5.7v30h-5.7V302.1z"/>
                        <path className="st9" d="M389.3,328.7c0,0-3.1,3.9-8.2,3.9c-4.9,0-9-2.6-9-8.8v-16.6h-4.3v-5.2h4.3v-9.8l5.7-0.4v10.1h8.2v5.2h-8.2v15
                            c0,3.2,2.2,5,4.4,5c2.6,0,4.4-2.5,4.4-3.1L389.3,328.7z"/>
                        <path className="st9" d="M394.2,292c0-1.8,1.4-3.2,3.2-3.2c1.7,0,3.2,1.4,3.2,3.2s-1.5,3.2-3.2,3.2C395.7,295.2,394.2,293.8,394.2,292z
                            M394.6,302.1h5.7v30h-5.7V302.1z"/>
                        <path className="st9" d="M432.6,302.1l-10.4,30h-5.7l-10.5-30h5.7l7.6,21.8l7.6-21.8H432.6z"/>
                        <path className="st9" d="M464.7,316.3l-24.3,4.4c1.3,3.7,4.8,6.4,8.9,6.4c5.4,0,9.4-3.8,10.3-6.4l4.9,1.6c-1.1,2.9-6,10-15.2,10
                            c-6.4-0.1-12.5-4.3-14.7-10.6c-0.5-1.6-0.8-3.2-0.8-4.9c0-8.6,6.9-15.5,15.5-15.5c8,0.1,14.8,6.3,15.4,14.2
                            C464.7,315.9,464.7,316.1,464.7,316.3z M458.2,312.9c-1.4-3.9-5.3-6.6-9.5-6.5c-4.7,0.2-8.6,4-9,8.6c0,0.2,0,0.6-0.1,1.1
                            L458.2,312.9z"/>
                        <path className="st9" d="M485.1,326.1l3.9-3.9c0.8,1,5,5,10,5c4.6,0,7.4-2,7.4-5.4c0-7.9-20.9-7.6-20.9-19.6c0-6,5.2-10.8,13-10.9
                            c6.7,0,12.7,5.2,13.9,6.5l-3.9,3.9c-1-1-5.2-5-10-5c-4.6,0-7.4,2-7.4,5.4c0,7.9,20.9,7.6,20.9,19.6c0,6-5.2,10.9-13,10.9
                            C492.2,332.6,486.3,327.4,485.1,326.1z"/>
                        <path className="st9" d="M515.6,317.1c0-8.5,6.9-15.5,15.5-15.5c5.6,0,10.6,3.1,13.3,7.6l-5.1,2.5c-1.9-2.8-5-4.7-8.2-4.7
                            c-5.6,0-9.8,4.3-9.8,10.1s4.2,10.1,9.8,10.1c3.2,0,6.2-1.9,8.2-4.7l5.1,2.5c-2.7,4.6-7.6,7.6-13.3,7.6
                            C522.5,332.6,515.6,325.6,515.6,317.1z"/>
                        <path className="st9" d="M550.8,292c0-1.8,1.4-3.2,3.2-3.2c1.7,0,3.2,1.4,3.2,3.2s-1.5,3.2-3.2,3.2C552.2,295.2,550.8,293.8,550.8,292z
                            M551.1,302.1h5.7v30h-5.7V302.1z"/>
                        <path className="st9" d="M593.9,316.3l-24.3,4.4c1.3,3.7,4.8,6.4,8.9,6.4c5.4,0,9.4-3.8,10.3-6.4l4.9,1.6c-1.1,2.9-6,10-15.2,10
                            c-6.4-0.1-12.5-4.3-14.7-10.6c-0.5-1.6-0.8-3.2-0.8-4.9c0-8.6,6.9-15.5,15.5-15.5c8,0.1,14.8,6.3,15.4,14.2
                            C593.9,315.9,594,316.1,593.9,316.3z M587.4,312.9c-1.4-3.9-5.3-6.6-9.5-6.5c-4.7,0.2-8.6,4-9,8.6c0,0.2,0,0.6-0.1,1.1L587.4,312.9
                            z"/>
                        <path className="st9" d="M625.9,314.8v17.3h-5.8V315c0-4.6-3.3-8.1-7.7-8.1s-7.7,3.5-7.7,8.2v17H599v-30h5.7v3.7
                            c2.2-2.6,5.5-4.2,8.9-4.2C620.7,301.6,625.9,307.5,625.9,314.8z"/>
                        <path className="st9" d="M631,317.1c0-8.5,6.9-15.5,15.5-15.5c5.6,0,10.6,3.1,13.3,7.6l-5.1,2.5c-1.9-2.8-5-4.7-8.2-4.7
                            c-5.6,0-9.8,4.3-9.8,10.1s4.2,10.1,9.8,10.1c3.2,0,6.2-1.9,8.2-4.7l5.1,2.5c-2.7,4.6-7.6,7.6-13.3,7.6
                            C637.9,332.6,631,325.6,631,317.1z"/>
                        <path className="st9" d="M694.2,316.3l-24.3,4.4c1.3,3.7,4.8,6.4,8.9,6.4c5.4,0,9.4-3.8,10.3-6.4l4.9,1.6c-1.1,2.9-6,10-15.2,10
                            c-6.4-0.1-12.5-4.3-14.7-10.6c-0.5-1.6-0.8-3.2-0.8-4.9c0-8.6,6.9-15.5,15.5-15.5c8,0.1,14.8,6.3,15.4,14.2
                            C694.2,315.9,694.2,316.1,694.2,316.3z M687.7,312.9c-1.4-3.9-5.3-6.6-9.5-6.5c-4.7,0.2-8.6,4-9,8.6c0,0.2,0,0.6-0.1,1.1
                            L687.7,312.9z"/>
                        <path className="st9" d="M714.6,326.1l3.9-3.9c0.8,1,5,5,10,5c4.6,0,7.4-2,7.4-5.4c0-7.9-20.9-7.6-20.9-19.6c0-6,5.2-10.8,13-10.9
                            c6.7,0,12.7,5.2,13.9,6.5l-3.9,3.9c-1-1-5.2-5-10-5c-4.6,0-7.4,2-7.4,5.4c0,7.9,20.9,7.6,20.9,19.6c0,6-5.2,10.9-13,10.9
                            C721.7,332.6,715.8,327.4,714.6,326.1z"/>
                        <path className="st9" d="M765.9,328.7c0,0-3.1,3.9-8.2,3.9c-4.9,0-9-2.6-9-8.8v-16.6h-4.3v-5.2h4.3v-9.8l5.7-0.4v10.1h8.2v5.2h-8.2v15
                            c0,3.2,2.2,5,4.4,5c2.6,0,4.4-2.5,4.4-3.1L765.9,328.7z"/>
                        <path className="st9" d="M796.1,302.1v30h-5.7v-3.7c-2.2,2.6-5.4,4.2-8.8,4.2c-7.2,0-12.4-5.8-12.4-13.2v-17.3h5.8v17.1
                            c0,4.6,3.3,8.1,7.7,8.1s7.7-3.5,7.7-8.2v-17H796.1z"/>
                        <path className="st9" d="M833.1,287.4v44.7h-5.7v-3.3c-2.7,2.3-6.2,3.8-10.1,3.8c-8.6,0-15.5-7-15.5-15.5c0-8.5,6.9-15.5,15.5-15.5
                            c3.9,0,7.4,1.4,10.1,3.8v-18H833.1z M827.1,317.1c0-5.8-4.2-10.1-9.8-10.1s-9.8,4.3-9.8,10.1s4.2,10.1,9.8,10.1
                            S827.1,322.9,827.1,317.1z"/>
                        <path className="st9" d="M869.4,316.3l-24.3,4.4c1.3,3.7,4.8,6.4,8.9,6.4c5.4,0,9.4-3.8,10.3-6.4l4.9,1.6c-1.1,2.9-6,10-15.2,10
                            c-6.4-0.1-12.5-4.3-14.7-10.6c-0.5-1.6-0.8-3.2-0.8-4.9c0-8.6,6.9-15.5,15.5-15.5c8,0.1,14.8,6.3,15.4,14.2
                            C869.4,315.9,869.5,316.1,869.4,316.3z M862.9,312.9c-1.4-3.9-5.3-6.6-9.5-6.5c-4.7,0.2-8.6,4-9,8.6c0,0.2,0,0.6-0.1,1.1
                            L862.9,312.9z"/>
                        <path className="st9" d="M901.4,314.8v17.3h-5.8V315c0-4.6-3.3-8.1-7.7-8.1s-7.7,3.5-7.7,8.2v17h-5.7v-30h5.7v3.7
                            c2.2-2.6,5.5-4.2,8.9-4.2C896.2,301.6,901.4,307.5,901.4,314.8z"/>
                        <path className="st9" d="M926.8,328.7c0,0-3.1,3.9-8.2,3.9c-4.9,0-9-2.6-9-8.8v-16.6h-4.3v-5.2h4.3v-9.8l5.7-0.4v10.1h8.2v5.2h-8.2v15
                            c0,3.2,2.2,5,4.4,5c2.6,0,4.4-2.5,4.4-3.1L926.8,328.7z"/>
                        <path className="st9" d="M973.7,320.8h-17.5l-4.5,11.3h-6l16.6-40.3h5.4l16.6,40.3h-6L973.7,320.8z M971.5,315.1l-6.5-16.4l-6.5,16.4
                            H971.5z"/>
                        <path className="st9" d="M986.9,327.2l3.8-3.3c0,0,3.7,3.7,8.7,3.7c3.6,0,6.1-1.3,6.1-3.7c0-4.6-18.7-3.7-18.7-13.4
                            c0-5,3.8-8.8,11.5-8.8c7.9,0,12.4,5.3,12.4,5.3l-3.8,3.4c0,0-3.7-3.7-8.7-3.7c-3.6,0-6.1,1.3-6.1,3.7c0,4.6,18.7,3.7,18.7,13.4
                            c0,5-3.8,8.8-11.6,8.8C991.4,332.6,986.9,327.2,986.9,327.2z"/>
                        <path className="st9" d="M1015.4,327.2l3.8-3.3c0,0,3.7,3.7,8.7,3.7c3.6,0,6.1-1.3,6.1-3.7c0-4.6-18.7-3.7-18.7-13.4
                            c0-5,3.8-8.8,11.5-8.8c7.9,0,12.4,5.3,12.4,5.3l-3.8,3.4c0,0-3.7-3.7-8.7-3.7c-3.6,0-6.1,1.3-6.1,3.7c0,4.6,18.7,3.7,18.7,13.4
                            c0,5-3.8,8.8-11.6,8.8C1019.9,332.6,1015.4,327.2,1015.4,327.2z"/>
                        <path className="st9" d="M1043.1,317.1c0-8.5,6.9-15.5,15.5-15.5c8.5,0,15.5,7,15.5,15.5c0,8.5-7,15.5-15.5,15.5
                            C1050,332.6,1043.1,325.6,1043.1,317.1z M1068.5,317.1c0-5.8-4.3-10.1-9.8-10.1c-5.6,0-9.8,4.4-9.8,10.1s4.2,10.1,9.8,10.1
                            C1064.2,327.2,1068.5,322.9,1068.5,317.1z"/>
                        <path className="st9" d="M1077.9,317.1c0-8.5,6.9-15.5,15.5-15.5c5.6,0,10.6,3.1,13.3,7.6l-5.1,2.5c-1.9-2.8-5-4.7-8.2-4.7
                            c-5.6,0-9.8,4.3-9.8,10.1s4.2,10.1,9.8,10.1c3.2,0,6.2-1.9,8.2-4.7l5.1,2.5c-2.7,4.6-7.6,7.6-13.3,7.6
                            C1084.8,332.6,1077.9,325.6,1077.9,317.1z"/>
                        <path className="st9" d="M1113.1,292c0-1.8,1.4-3.2,3.2-3.2c1.7,0,3.2,1.4,3.2,3.2s-1.5,3.2-3.2,3.2
                            C1114.5,295.2,1113.1,293.8,1113.1,292z M1113.4,302.1h5.7v30h-5.7V302.1z"/>
                        <path className="st9" d="M1156.7,302.1v30h-5.7v-3.3c-2.7,2.3-6.2,3.8-10.1,3.8c-8.6,0-15.5-7-15.5-15.5c0-8.5,6.9-15.5,15.5-15.5
                            c3.9,0,7.4,1.4,10.1,3.8v-3.3H1156.7z M1150.8,317.1c0-5.8-4.2-10.1-9.8-10.1c-5.5,0-9.8,4.3-9.8,10.1s4.3,10.1,9.8,10.1
                            C1146.6,327.2,1150.8,322.9,1150.8,317.1z"/>
                        <path className="st9" d="M1182.4,328.7c0,0-3.1,3.9-8.2,3.9c-4.9,0-9-2.6-9-8.8v-16.6h-4.3v-5.2h4.3v-9.8l5.7-0.4v10.1h8.2v5.2h-8.2v15
                            c0,3.2,2.2,5,4.4,5c2.6,0,4.4-2.5,4.4-3.1L1182.4,328.7z"/>
                        <path className="st9" d="M1187.4,292c0-1.8,1.4-3.2,3.2-3.2c1.7,0,3.2,1.4,3.2,3.2s-1.5,3.2-3.2,3.2
                            C1188.8,295.2,1187.4,293.8,1187.4,292z M1187.7,302.1h5.7v30h-5.7V302.1z"/>
                        <path className="st9" d="M1199.7,317.1c0-8.5,6.9-15.5,15.5-15.5c8.5,0,15.5,7,15.5,15.5c0,8.5-7,15.5-15.5,15.5
                            C1206.6,332.6,1199.7,325.6,1199.7,317.1z M1225.1,317.1c0-5.8-4.3-10.1-9.8-10.1c-5.6,0-9.8,4.4-9.8,10.1s4.2,10.1,9.8,10.1
                            C1220.8,327.2,1225.1,322.9,1225.1,317.1z"/>
                        <path className="st9" d="M1262.7,314.8v17.3h-5.8V315c0-4.6-3.3-8.1-7.7-8.1c-4.4,0-7.7,3.5-7.7,8.2v17h-5.7v-30h5.7v3.7
                            c2.2-2.6,5.5-4.2,8.9-4.2C1257.5,301.6,1262.7,307.5,1262.7,314.8z"/>
                    </g>
                    <g className="st25">
                        <path className="st9" d="M241.6,374.1v30h-5.7v-3.3c-2.7,2.3-6.2,3.8-10.1,3.8c-8.6,0-15.5-7-15.5-15.5c0-8.5,6.9-15.5,15.5-15.5
                            c3.9,0,7.4,1.4,10.1,3.8v-3.3H241.6z M235.6,389.1c0-5.8-4.2-10.1-9.8-10.1c-5.5,0-9.8,4.3-9.8,10.1s4.3,10.1,9.8,10.1
                            C231.4,399.2,235.6,394.9,235.6,389.1z"/>
                        <path className="st9" d="M267.3,400.7c0,0-3.1,3.9-8.2,3.9c-4.9,0-9-2.6-9-8.8v-16.6h-4.3v-5.2h4.3v-9.8l5.7-0.4v10.1h8.2v5.2h-8.2v15
                            c0,3.2,2.2,5,4.4,5c2.6,0,4.4-2.5,4.4-3.1L267.3,400.7z"/>
                        <path className="st9" d="M288,388.7v-24.9h5.7v24.9c0,6.5,4.5,10.5,10.4,10.5c5.9,0,10.4-4,10.4-10.5v-24.9h5.7v24.9
                            c0,10-7.2,15.9-16.1,15.9C295.3,404.6,288,398.7,288,388.7z"/>
                        <path className="st9" d="M326.8,384c0-11.4,7.3-20.6,19.7-20.6c7.8,0,13.6,3.8,16.8,9.5l-5.2,3.2c-2.3-4.4-6.5-7.3-11.6-7.3
                            c-8.3,0-13.4,6.5-13.4,15.2c0,8.7,5.2,15.2,13.4,15.2c5.1,0,9.4-3,11.6-7.4l5.2,3.2c-3.2,5.7-9,9.5-16.8,9.5
                            C334.1,404.6,326.8,395.4,326.8,384z"/>
                        <path className="st9" d="M383.5,398.1l3.9-3.9c0.8,1,5,5,10,5c4.6,0,7.4-2,7.4-5.4c0-7.9-20.9-7.6-20.9-19.6c0-6,5.2-10.8,13-10.9
                            c6.7,0,12.7,5.2,13.9,6.5l-3.9,3.9c-1-1-5.2-5-10-5c-4.6,0-7.4,2-7.4,5.4c0,7.9,20.9,7.6,20.9,19.6c0,6-5.2,10.9-13,10.9
                            C390.7,404.6,384.7,399.4,383.5,398.1z"/>
                        <path className="st9" d="M445.4,374.1v30h-5.7v-3.3c-2.7,2.3-6.2,3.8-10.1,3.8c-8.6,0-15.5-7-15.5-15.5c0-8.5,6.9-15.5,15.5-15.5
                            c3.9,0,7.4,1.4,10.1,3.8v-3.3H445.4z M439.5,389.1c0-5.8-4.2-10.1-9.8-10.1c-5.5,0-9.8,4.3-9.8,10.1s4.3,10.1,9.8,10.1
                            C435.3,399.2,439.5,394.9,439.5,389.1z"/>
                        <path className="st9" d="M479.5,386.8v17.3h-5.8V387c0-4.6-3.3-8.1-7.7-8.1s-7.7,3.5-7.7,8.2v17h-5.7v-30h5.7v3.7
                            c2.2-2.6,5.5-4.2,8.9-4.2C474.3,373.6,479.5,379.5,479.5,386.8z"/>
                        <path className="st9" d="M501.6,363.9h9.8c13.3,0,21.2,9,21.2,20.2c0,11.1-7.9,20.1-21.2,20.1h-9.8V363.9z M511.5,398.7
                            c9.3,0,15.1-6.3,15.1-14.7c0-8.5-5.8-14.8-15.1-14.8h-4.1v29.5H511.5z"/>
                        <path className="st9" d="M538.9,364c0-1.8,1.4-3.2,3.2-3.2c1.7,0,3.2,1.4,3.2,3.2s-1.5,3.2-3.2,3.2C540.3,367.2,538.9,365.8,538.9,364z
                            M539.2,374.1h5.7v30h-5.7V374.1z"/>
                        <path className="st9" d="M582,388.3l-24.3,4.4c1.3,3.7,4.8,6.4,8.9,6.4c5.4,0,9.4-3.8,10.3-6.4l4.9,1.6c-1.1,2.9-6,10-15.2,10
                            c-6.4-0.1-12.5-4.3-14.7-10.6c-0.5-1.6-0.8-3.2-0.8-4.9c0-8.6,6.9-15.5,15.5-15.5c8,0.1,14.8,6.3,15.4,14.2
                            C582,387.9,582.1,388.1,582,388.3z M575.5,384.9c-1.4-3.9-5.3-6.6-9.5-6.5c-4.7,0.2-8.6,4-9,8.6c0,0.2,0,0.6-0.1,1.1L575.5,384.9z"
                            />
                        <path className="st9" d="M618.3,374.2v27.5c-0.1,9-7.7,15.2-15.5,15.2c-9.2,0-13.9-6.4-14.9-9.3l4.6-2.3c1,2.6,5,6.4,10.4,6.4
                            c5.9,0,9.7-5.3,9.8-10h-0.1v-0.8c-2.7,2.3-6.2,3.8-10.1,3.8c-8.6,0-15.5-6.9-15.5-15.5c0-8.5,6.9-15.5,15.5-15.5
                            c3.9,0,7.4,1.4,10.1,3.8v-3.3H618.3z M612.4,389.2c0-5.7-4.3-10.1-9.8-10.1c-5.6,0-9.8,4.4-9.8,10.1c0,5.8,4.2,10.1,9.8,10.1
                            C608.2,399.3,612.4,395,612.4,389.2z"/>
                        <path className="st9" d="M624,389.1c0-8.5,6.9-15.5,15.5-15.5c8.5,0,15.5,7,15.5,15.5c0,8.5-7,15.5-15.5,15.5
                            C630.9,404.6,624,397.6,624,389.1z M649.4,389.1c0-5.8-4.3-10.1-9.8-10.1c-5.6,0-9.8,4.4-9.8,10.1s4.2,10.1,9.8,10.1
                            C645.1,399.2,649.4,394.9,649.4,389.1z"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <rect width="1440" height="880" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>

            </div>

            <div className="container">
                < HomeInitial/>
                <div className="row py-5" id="learn-more">
                    <div className="col-sm-8 order-sm-2">
                        <div className="home-section" data-aos="fade-up">
                            <img className="pb-3" src={Logo} alt="CSSA @ UCSD"></img>
                        </div>
                        <div className="home-section" data-aos="fade-up">
                            <p className="words">Dynamic. Professional. Honest.</p>
                        </div>
                        <div className="home-section" data-aos="fade-up">
                            <p>CSSA is a professional, academic and social organization for students 
                                interested in exploring the interdisciplinary field of Cognitive Science. </p>
                        </div>
                        <div className="home-section" data-aos="fade-up">
                            <p>Our mission is to equip students with the practical skills required to 
                                succeed in the various career paths that a cognitive science degree offers. 
                                We aim to encourage growth and opportunity by building an integrated 
                                community of students with similar goals and interests.</p>
                        </div>
                    </div>
                    <div className="col-sm-4 order-sm-1">
                        < Sidebar/>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default Home;