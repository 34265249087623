const advisorsData = [
    {
        id: 1,
        name: "Lara Rangel", 
        position: "Assistant Professor",
        img: "advisors/laraRangel.jpg",
        imgHover: "advisors/laraRangel.jpg",
        url: "https://www.facebook.com/webraincogsci"
    }
]

export default advisorsData;