const presAdvisorsData = [ 
    {
        id: 40,
        name: "Anjali Mathi", 
        position: "Presidential Advisor",
        img: "exec_2023_2024/anjalim.JPG",
        imgHover: "exec_2023_2024/anjalim_hover.JPG",
        url: "https://www.facebook.com/webraincogsci"
    },
]

export default presAdvisorsData;