const supportUsData = [
    // {
    //     id: 1,
    //     title: "Order CSSA Merchandise Today!",
    //     url: "https://docs.google.com/forms/d/e/1FAIpQLScptuvQwVyekk03k39C4QOBM1K_9KR8UhbzImZUcghTZVThtQ/viewform"
    // },
    {
        id: 2,
        title: "Follow Us on Social Media!",
        url: "https://linktr.ee/CSSA_UCSD"
    }
]

export default supportUsData;